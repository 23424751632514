<template>
  <div class="body fixed mobile">
    <!-- Loading Spinner -->
    <spinner :spin="loading"/>
    <!-- Header -->
    
    <section class="header index">
            <a class="left">
                &nbsp;
            </a>
            <div class="uide-page-name">掃碼</div>
            <div class="right">
                <!-- <a class="icon iconButton" @click.prevent="barcodePopupOpen = true"> -->
                <a class="icon iconButton" @click.prevent="barcodePopupOpen = true">
                    
                    <!-- <font-awesome-icon icon="fa-solid fa-barcode" size="xl"></font-awesome-icon> -->
                    <img  src="@/assets/img/barcode.svg">
                </a>                          
            </div>
        </section>
    <!-- Main -->
    <section class="main scan">
      <!-- <a class="button fit rounded" @click.prevent="popCode()"
                ><span class="fS">手動輸入代碼</span></a
            > -->
      <QRCodeScanner
        ref="qrCodeScanner"
        @create-video="videoHandler"
        @video-ready="getReady($event)"
        @store-code-ready="onStoreCodeReady"
        @get-url="getUrl"
        @get-checkin="getCheckin"
        @get-checkin-random="getCheckin"
      />
      <!-- <div :class="['input', 'rounded', { error: isError }]">
        <input
          type="text"
          class="fM"
          placeholder="手動輸入代碼"
          v-model="storeCode"
          @keyup.enter="goToUsePoint()"
        />
        <span v-if="storeCode" class="fS error message">商店代碼無效</span>
        <span v-else class="fS error message"
          >請輸入商店代碼，或使用相機掃碼</span
        >
        <a class="icon iconButton" @click.prevent="goToUsePoint()"
          ><font-awesome-icon icon="fa-solid fa-arrow-right"
        /></a>
      </div> -->
      <a
        class="button fit rounded"
        @click.prevent="barcodePopupOpen = true"
        ><span class="fS">我的條碼</span></a
      >
    </section>
    <!-- Popup (Code) -->
    <BarcodePopup
      v-if="barcodePopupOpen"
      @closePopup="barcodePopupOpen = false"
    />
    <!-- Popup (Notice) -->
    <section id="popNotice" class="popup">
      <div class="popBody">
        <a class="close"><font-awesome-icon icon="fa-solid fa-times" /></a>
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain">
          <span class="fL">無法建立付款訂單</span>
        </section>
        <section class="popFooter">
          <a class="button submit rounded"><span class="fM">確認</span></a>
        </section>
      </div>
    </section>
    <AlertDialog
      :title="resultTitle"
      :content="resultMessage"
      v-if="showResultDialog"
      @close="onCloseResultDialog"
    />
    <section id="popLeave" class="popup">
      <div class="popBody">
        <a class="close" @click.prevent="closeLeaveModal"
          ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
        ></a>
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain">
          <div class="fL text-center">提示</div>
          <div class="icon"></div>
          <span class="">您即將離開 App 前往外部連結，請問是否繼續？</span>
        </section>
        <section class="popFooter flex">
          <a
            class="button bg-transparent text-black border border-primary flex-1 submit rounded"
            @click.prevent="closeLeaveModal"
            >返回</a
          >
          <a class="button flex-1 submit rounded" @click.prevent="goOut()"
            ><span class="fM">確認</span></a
          >
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

import QRCodeScanner from '@/components/QRCodeScanner.vue';
import BarcodePopup from '@/components/BarcodePopup.vue';
import Spinner from '@/components/Spinner.vue';
import AlertDialog from '@/components/Dialog/AlertDialog.vue'

import usePay from '@/utils/usePay';

export default {
  name: 'Scanner',
  components: {
    QRCodeScanner,
    BarcodePopup,
    Spinner,
    AlertDialog
  },
  data: function () {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      storeCode: '',
      video: null,
      isError: false,
      loading: false,
      barcodePopupOpen: false,
      resultTitle: '',
      resultMessage: '',
      showResultDialog: false,
      redirectUrl: '',
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        next('/error');
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    console.log(to, from);
    this.redirectUrl = ''
    this.stopStreamedVideo();
    next();
  },
  computed: {
    ...mapState(['user', 'userInfo']),
  },
  // mounted() {
  //   this.getCheckin('3dfe67ed-f588-4e49-81b2-865d2513fc46');
  // },
  methods: {
    goOut() {
      window.open(this.redirectUrl, '_blank') || window.location.assign(this.redirectUrl)
      setTimeout(() => {
        this.closeLeaveModal();
      }, 1000);
    },
    linkClickHandler(url) {
      this.openLeaveModal();
      this.redirectUrl = url;
    },
    openLeaveModal() {
      const wrap = document.querySelector("#popLeave");
      wrap.style.display = "flex";
    },
    closeLeaveModal() {
      const wrap = document.querySelector("#popLeave");
      wrap.style.display = "none";
      this.$refs.qrCodeScanner.refresh()
    },
    onCloseResultDialog() {
      this.showResultDialog = false;
      this.resultTitle = '';
      this.resultMessage = '';
      this.$refs.qrCodeScanner.refresh();
      if (this.redirectUrl) {
        window.open(this.redirectUrl, '_blank') || window.location.assign(this.redirectUrl)
        this.redirectUrl = ''
      }
    },
    getUrl(url) {
      try {
        const { getRedirectUrl } = usePay();
        const redirectUrl = getRedirectUrl({
          userId: this.user.userId,
          userName: this.user.displayName,
          userPhone: this.user.phone,
          userEmail: this.user.user.email,
          nextUrl: url,
        });

        this.linkClickHandler(redirectUrl);

        // this.resultTitle = '提示'
        // this.resultMessage = '將為您開啟網頁'
        // this.showResultDialog = true
        // this.redirectUrl = redirectUrl
        // window.open(redirectUrl, '_blank') || window.location.assign(redirectUrl)
      } catch (error) {        
        this.$refs.qrCodeScanner.showScannerError("網址有誤");
      } finally {
        // setTimeout(() => {
        //   this.$refs.qrCodeScanner.refresh();
        // }, 1000);
      }
    },
    getCheckin(id) {
      console.log('getCheckin', id);
      const url = `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/ticket/${id}/member/join`;
      this.$http({
        url,
        method: 'POST',
        data: {
          uuid: this.user.userId,
          member_id: this.userInfo.memberId,
          nonce: Date.now()
        }
      })
        .then((res) => {
          // TODO: Check success response
          
          console.log('getCheckin res', res);

          const mission = res.data?.mission
          if (mission) {
            this.resultTitle = this.$t('check-in-success.title')
            this.resultMessage = this.$t('check-in-success.content', {
              name: _.get(mission, 'campaign_name', '測試活動'),
              point: _.get(mission, 'reward_point', '1')
            })
            this.showResultDialog = true
            return
          }

          const message = res.data?.message?.message
          if (message) {
            this.resultTitle = this.$t('check-in-failed.title')
            this.resultMessage = message
            this.showResultDialog = true
            return
          }

          this.resultTitle = this.$t('check-in-failed.title')
          this.resultMessage = this.$t('check-in-failed.content')
          this.showResultDialog = true
        })
        .catch((err) => {
          console.log('getCheckin err', err);
        });
    },
    onStoreCodeReady(code) {
      this.storeCode = code;
      // this.goToUsePoint();
    },
    goToUsePoint() {
      if (!this.storeCode) {
        this.isError = true;
        return;
      }
      let supportOnlinePayment = false;
      this.getAppointedStoreInfo(this.storeCode)
        .then((getAppointedStoreInfoRes) => {
          console.log('getAppointedStoreInfoRes: ', getAppointedStoreInfoRes);
          supportOnlinePayment = getAppointedStoreInfoRes.payment?.shopId;
          // TBD: 建立付款訂單&取得token，並重導致用點頁面或點＋金頁面
          let orderInfo = {
            shopId: getAppointedStoreInfoRes.payment?.shopId, // 金流用
            store: {
              storeId: getAppointedStoreInfoRes.storeId,
              storeName: getAppointedStoreInfoRes.storeName,
              resourceEntityType: getAppointedStoreInfoRes.resourceEntityType,
              resourceEntityId: getAppointedStoreInfoRes.resourceEntityId,
            },
            // "expirationTs": moment().add(20, 'minutes').unix()
          };
          console.log('create order token');
          this.createOrder(orderInfo)
            .then((createTokenRes) => {
              const token = createTokenRes?.token;

              if (supportOnlinePayment) {
                this.$router.push({
                  path: 'consumption',
                  query: { storeCode: this.storeCode, token },
                });
              } else {
                this.$router.push({
                  // path: "consumption",
                  path: 'point-consumption',
                  query: { storeCode: this.storeCode, token },
                });
              }
            })
            .catch((err) => {
              // TBD
              console.log('無法建立付款訂單', err);
              this.popNotice();
            });
          // this.$router.push({
          //     path: "consumption",
          //     query: { storeCode: this.storeCode }
          // });
        })
        .catch((getAppointedStoreInfoErr) => {
          console.log('getAppointedStoreInfoErr: ', getAppointedStoreInfoErr);
          this.isError = true;
        });
    },
    videoHandler(e) {
      this.video = e;
    },
    stopStreamedVideo() {
      this.$refs.qrCodeScanner.stopStreamedVideo();
    },
    popNotice() {
      var popNotice = document.getElementById('popNotice');
      var close = popNotice.getElementsByClassName('close')[0];
      var submit = popNotice.getElementsByClassName('submit')[0];
      close.onclick = function () {
        popNotice.style.display = 'none';
      };
      submit.onclick = function () {
        popNotice.style.display = 'none';
      };
      window.onclick = function (event) {
        if (event.target == popNotice) {
          popNotice.style.display = 'none';
        }
      };
      popNotice.style.display = 'flex';
    },
    getAppointedStoreInfo(storeCode) {
      console.log(storeCode);
      var config = {
        method: 'get',
        url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/store-codes/${storeCode}`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          throw error;
        });
    },
    getReady(isReady) {
      if (isReady) {
        this.loading = false;
      } else {
        //
        this.loading = false;
      }
    },
    createOrder(data) {
      let config = {
        url: `${this.apiHost}/payment/v1/merchants/${this.merchantId}/orders`,
        method: 'POST',
        data,
      };
      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (createOrderErr) {
          console.log('createOrder Err: ', createOrderErr);
          throw createOrderErr;
        });
    },
  },
  watch: {
    storeCode: function () {
      this.isError = false;
    },
  },
};
</script>

<style>
.vue-barcode-element {
  width: 100%;
}
</style>
